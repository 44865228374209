<!-- eslint-disable -->
<template>
  <!-- Content Section Start -->
  <div class="mt-6">
    <!-- Page title Section Start -->
    <div id="page-title" class="page-title-block" :style="style">
      <div class="container">
        <h1 class="post-title">Edit contact info</h1>
      </div>
    </div>
    <!-- Page title Section End -->
    <!-- Benefits Start -->
    <div id="page-content">
      <div class="container">
        <div class="row">
          <div class="col-md-3 sidebar">
            <div class="widget-area">
              <aside id="categories-1" class="widget widget_categories">
                <h4 class="widget-title">Profile</h4>
                <ul>
                  <li
                    v-for="link in navLinks"
                    :key="link.id"
                  >
                    <router-link
                      :to="link.to"
                    >
                      {{ $t(link.label) }}
                    </router-link>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
          <div class="col-md-9 order-first order-md-last">
            <article class="post">
              <div class="container">
                <div class="contact-section">
                  <div class="col-md-1" />
                  <div class="col-md-10 text-center">
                    <div class="sec-title text-center">
                      <h3>Edit your profile</h3>
                    </div>
                    <div class="row">
                      <div class="form-row">
                        <div class="form-group col-md-12">
                          <input v-model="user.username" type="text" class="form-control" placeholder="Username">
                        </div>
                        <div class="form-group col-md-12">
                          <input v-model="user.email" type="text" class="form-control" placeholder="Email Address">
                        </div>
                        <div class="form-group col-md-12">
                          <input v-model="user.wallet_address" type="text" class="form-control" placeholder="Wallet Address">
                        </div>
                        <div class="form-group col-md-12">
                          <input v-model="user.holdings" type="text" class="form-control" placeholder="Number of NFTS">
                        </div>
                        <div class="form-group col-md-12">
                          <input v-model="user.phone_number" type="tel" class="form-control" placeholder="Phone Number">
                        </div>
                        <div class="form-group col-md-12">
                          <input v-model="user.referral_id" type="tel" class="form-control" placeholder="Referral Id">
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <button type="button" class="btn btn-save" @click="onSave">Save</button>
                    </div>
                  </div>
                  <div class="col-md-1" />
                </div>
              </div>
              <!-- Hero Section End -->
            </article>
          </div>
        </div>
      </div>
    </div>
    <!-- Benefits End -->
  </div>
</template>

<script>
  /* eslint-disable */
  import Vue from 'vue';
  import { mapActions, mapGetters } from 'vuex';
  import { nanoid } from 'nanoid';
  import { PROFILE_LINKS } from '@/constants/navbar-links';
  import backgroundBanner from '@/assets/jpg/profile.jpg';

  export default Vue.extend({
    name: 'elros-profile',
    data: () => ({
      style: { backgroundImage: `url(${backgroundBanner})` },
      user: {
        username: '',
        email: '',
        phone_number: '',
        wallet_address: '',
        referral_id: '',
      },
    }),
    computed: {
      ...mapGetters({
        getUser: 'session/getUser',
      }),
      navLinks() {
        const isAdmin = this.getUser.role === 'admin';

        if (isAdmin) {
          const hasAdmin = PROFILE_LINKS.find(({ label }) => label === 'Admin');

          if (!hasAdmin) {
            PROFILE_LINKS.push({ label: 'Admin', to: '/admin', needsAuth: true });
          }
        }
        return PROFILE_LINKS
          .filter(item => item.needsAuth)
          .map(item => ({ ...item, id: nanoid() }));
      },
    },
    created() {
      const { query } = this.$router.currentRoute;

      if (query && query.confirmation_token) {
        this.confirmUser(query.confirmation_token);
      }

      if (this.getUser.id) {
        this.user = { ...this.getUser };
      }
    },
    methods: {
      ...mapActions({
        confirmUser: 'session/confirmUser',
        updateUser: 'session/updateUser',
      }),
      onSave() {
        let data = {
          ...this.user,
          type: 'users',
        };

        delete data.relationshipNames;
        delete data.wallets;
        delete data.referrals;

        this.updateUser({
          credentials: { ...data },
        });
      },
    },
  });
</script>
<style>
  .btn-save,
  .copy-btn {
    background: #ea721c !important;
    background-color: #ea721c !important;
    border-radius: 4px;
    color: #1d1d1d;
    cursor: pointer;
    font-weight: 700;
    padding: 14px 30px;
    text-transform: uppercase;
  }

  .copy-btn {
    font-weight: 500;
    padding: 8px 16px;
  }
</style>
